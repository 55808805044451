<template>
  <div class="league-page">
    <template>
      <v-img style="height: 50px; width: 50px; margin: 5px;" :src="league.logo" />
    </template>

    <p class="mb-0 font-weight-bold">{{ league.name }}</p>
    <p class="caption">{{ league.country }} - {{ league.season }}</p>

    <custom-tabs />
  </div>
</template>

<script>
import CustomTabs from "@/views/League/components/CustomTabs";
import { mapGetters } from "vuex";

export default {
  name: "LeaguePage",

  components: { CustomTabs },

  data: () => ({}),

  computed: {
    ...mapGetters({
      league: "leagues/getLeague"
    })
  },

  mounted() {
    const leagueId = this.$route.params.id
    this.$store.dispatch("leagues/fetchOne", leagueId)
  }
}
</script>
